// state
import axios from "@/utils/axios";

let state = {
    image: {},
    subscribe_type: [
        {label: '仅外网浏览', value: 1},
        {label: '仅内网下载', value: 2},
        {label: '通用', value: 3},
    ],
    proxy_subscribe: [],
    is_yes_or_no: [
        {label: '是', value: 1, type: 'success'},
        {label: '否', value: 0, type: 'default'}
    ],
    is_refunded_yes_or_no: [
        {label: '是', value: 1, type: 'success'},
        {label: '否', value: 0, type: 'warning'}
    ],
    is_sms_verify: [
        {label: '已验证', value: 1, type: 'success'},
        {label: '未验证', value: 0, type: 'default'}
    ],
    is_sms_sent: [
        {label: '成功', value: 1, type: 'success'},
        {label: '失败', value: 0, type: 'warning'}
    ],
    refund_status: [
        {label: '成功', value: 1, type: 'success'},
        {label: '失败', value: 0, type: 'warning'}
    ],
    bind_status: [
        {label: '已绑定', value: 1, type: 'success'},
        {label: '未绑定', value: -1, type: 'success'},
        {label: '不支持绑定', value: 0, type: 'warning'}
    ],
    member_level: [
        {label: '年费会员', value: 100, type: 'success'},
        {label: '半年会员', value: 80, type: 'success'},
        {label: '季度会员', value: 50, type: 'warning'},
        {label: '月度会员', value: 1, type: 'warning'},
    ],
    product_type: [],
    proxy_server_usage: [
        {label: '未使用', type: '', value: ''},
        {label: '未使用', type: '', value: undefined},
        {label: '外网', type: 'success', value: 'outer'},
        {label: '内网', type: 'primary', value: 'inner'}
    ],
    proxy_server_status: [
        {label: '未使用', type: '', value: 1},
        {label: '使用中', type: 'success', value: 2},
        {label: '被封', type: 'danger', value: 100}
    ],
    review_status: [
        {label: '未审核', type: '', value: 1},
        {label: '通过', type: 'success', value: 200},
        {label: '不通过', type: 'danger', value: 0}
    ],
    member_type: [
        {label: '安卓', value: 1},
        {label: 'GIS Office', type: 'success', value: 'pc'},
        {label: 'IOS', type: 'danger', value: 'ios'}
    ],
    pay_status: [
        {label: '已付款', type: 'success', value: 1},
        {label: '未付款', value: 0, type: 'danger'},
        {label: '订单关闭', type: 'danger', value: 10},
        {label: '退款中', type: 'danger', value: 8},
        {label: '退款完成', type: 'danger', value: 9},
        // {label: '赠送会员', type: 'danger', value: 60},
    ],
    platform_list: [
        {label: '安卓', value: 'android'},
        {label: 'GIS Office', type: 'success', value: 'pc'},
        {label: 'IOS', type: 'danger', value: 'ios'}
    ],
    platform_list_product: [
        {label: 'WEB平台', value: 'web'},
        {label: '其它', value: 'else'},
        {label: '安卓', value: 'android'},
        {label: 'GIS Office', type: 'success', value: 'pc'},
        {label: 'IOS', type: 'danger', value: 'ios'}
    ],
    user_group: [],
    bbs_user_group:[
        {label: '管理员', value: 1},
        {label: '普通用户', value: 2},
    ],
    pay_from: [
        {label: '支付宝', value: 'ali'},
        {label: '微信', value: 'wx'},
        // {label: 'GIS Office', type: 'success', value: 'pc'},
        {label: 'IOS', value: 'ios'},
        {label: 'IOS沙盒', value: 'ios_sand'},
    ],
    is_enabled: [
        {label: '启用', value: 1, type: 'success'},
        {label: '禁用', value: 0, type: 'danger'}
    ],
    offline_type: [],
    answer_type: [
        {label: 'Web前端', value: 'web'},
        {label: 'JavaScript', value: 'js'},
        {label: 'PHP', value: 'php'},
        {label: 'Linux', value: 'linux'},
        {label: '后端', value: 'back'},
        {label: '工作', value: 'work'},
        {label: '杂项', value: 'else'},
    ],
}

// mutations
const mutations = {
    // updateDict(state, dict) {
    //     console.log('aaaaaa',dict);
    //     state=Object.assign({},state,dict);
    //     // for (var k in dict) {
    //     //     state[k] = Object.assign({}, state[k], dict[k]);
    //     // }
    // },
}


const actions = {
    getDict(context, dict) {
        let url = dict._url ? dict._url : '/big/api/dict';
        axios(process.env.VUE_APP_HOST).get(url, {
            params: {
                dict: dict.join(',')
            }
        }).then(data => {
            context.commit('updateDict', data.data);
        }).catch(() => {
        });
    },
    // setDict(context, dict) {
    //     // console.log(dict);
    //     context.commit('updateDict', dict);
    // },
}

export default {
    state,
    actions,
    mutations
}
